#mainTitle {
  padding: 1% 2%;
  background-color: white;
  width: 50%;
}

h1 {
  font-size: 35px;
}

.bar {
  width: 90%;
  margin: auto;
  background-color: lightgrey;
  height: 20px;
}

.textBar {
  text-align: right;
  margin-right: 15px;
  color: #ffffff;
  position: relative;
  height: 20px;
}

.effectBar.textBar:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-top: 20px solid lightgrey;
  width: 0;
}

.bar:nth-of-type(odd) .effectBar.textBar:before {
  border-left: 20px solid steelblue;
}

.bar:nth-of-type(odd) .textBar {
  background-color: steelblue;
}

.bar:nth-of-type(even) .textBar {
  background-color: red;
}

.bar:nth-of-type(even) .effectBar.textBar:before {
  border-left: 20px solid red;
}

.fab {
  font-size: 25px;
}

h4 .language {
  padding-top: 2%;
}

.logoLang{
  height: 1.5rem;
  width: 1.5rem;
}
.visual{
  width: 10rem;
  border-style: solid;
  border-width: 0.5rem;
  border-color: steelblue;
}
.language {
  display: inline;
}

.language p{
  text-align: justify;
}
#lienRealisation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#lienRealisation a {
  flex: 1;
  padding: 0.8% 4%;
  margin: 0.5%;
  background-color: steelblue;
  color: white;
  border-radius: 10px;
  text-decoration: none;
  font-size: 25px;
  text-align: center;
}

#lienRealisation a:hover {
  background-color: red;
  color: black;
}

#lienRealisation a:active {
  background-color: green;
}

#contactBtn a {
  padding: 0.8% 4%;
  background-color: steelblue;
  color: white;
  border-radius: 10px;
  text-decoration: none;
  font-size: 17px;
  text-align: center;
}

#contactBtn a:hover {
  background-color: red;
  color: black;
}

@media (min-width: 780px) {
  #lienRealisation {
    flex-direction: row;
    justify-content: space-around;
  }
}

/*# sourceMappingURL=home.css.map */
